import "./style/style.scss";
import React, {	useState, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "LandingPage";
import Contact from "Contact/Contact";
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
function App() {

    const root = useRef();
	const [navOpen, setNavOpen] = useState(false)

	return (
		<div className="App" id='app-main' ref={root}>
			<div id='containerx' className="container flex-col" style={{position: navOpen ? 'sticky' : 'relative'}}>
				<Routes>
					<Route path="/" exact element={<LandingPage 
						setNavOpen={setNavOpen}
						root={root}
					/>} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
