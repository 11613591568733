import React from "react";

const Breadcrums = (props) => {
	return (
		<div className="breadcrum">
			<div className="breadcrum-wrapper">
				<span className="breadcrum1 intro-name">I'm Manan Soni</span>

				<span className="breadcrum1 intro-intend">I'm a <span className="gradient-text">Fullstack Developer</span> </span>
			</div>
		</div>
	);
};

export default Breadcrums;
