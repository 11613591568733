import React from "react";

const Heading = (props) => {
	return (
		<div className="heading">
			<ul>
				 <li>
					<div className="heading1"><span className="gradient-heading">React.js</span></div>
				</li>
				<li>
					<div className="heading1"><span className="gradient-heading">Node.js & Django</span></div>
				</li>
				<li>
					<div className="heading1"><span >Developer</span></div>
				</li>
				{/* <li>
<div className="heading1"><span className="non-gradient">For Your </span><span className="gradient-heading" >Frontend Problems</span></div>
</li> */}
			</ul>
		</div>
	);
};

export default Heading;

