import React, {useRef, useEffect} from "react";
import Breadcrums from "Breadcrums/Breadcrums";
import Heading from "Heading";
import Navbar from "Navbar/Navbar";
import Services from "Services/Services";
import { gsap, Power3 } from "gsap";
import Contact from "Contact/Contact";
const LandingPage = ({setNavOpen, root}) => {

  const head1 = useRef(null)

  useEffect(() => {

    const q = gsap.utils.selector(root);

    const ctx = gsap.context(()=>{
      // gsap.from([".img-snippet"], {
      //   duration: 0.8,
      //   y: 600,
      //   delay: 0.6,
      //   ease: "power3.inOut",
      //   stagger: {
      //     amount: 0.3,
      //   },
      // });
      gsap.from('.breadcrum-wrapper', {
        duration: 0.8,
        y: 200,
        delay: 0.1,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });
     
      gsap.fromTo(q(".navbar"),{ y: 15, opacity: 0 },{ duration:0.8, y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15 });
      gsap.from(['.img-snippet','.explore-text3'], {
        duration: 1.2,
        y: 120,
        opacity:0,
        delay: 0.15,
        ease: "power3.inOut",
        stagger: {
          amount: 0.8,
        },
      });

      // gsap.fromTo(q(".explore-text3"),{ opacity:1 },{
      //   opacity:0,
      //   delay:1,
      //   ease: "power3.inOut",
      //   scrollTrigger: {
      //     trigger: q(".explore-text3"),
      //     start: "top 60%",
      //     end: "top 30%",
      //     scrub: true,
      //     markers: true,
      //     id: "scrub"
      //   },
      //   onComplete: () => ScrollTrigger.refresh(), 
      // });

      gsap.from('.heading1', {
        duration: 1.2,
        y: 80,
        opacity:0,
        delay: 0.3,
        ease: "power3.inOut",
        stagger: {
          amount: 0.8,
        },
       
      });
      gsap.from(['.explore-text'], {
        duration: 1.2,
        x: -100,
        opacity:0,
        delay: 0.3,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });
      gsap.from(['.explore-text2'], {
        duration: 1.2,
        x: 100,
        opacity:0,
        delay: 0.3,
        ease: "power3.inOut",
        stagger: {
          amount: 0.3,
        },
      });

      gsap.fromTo(q(".explore-text2"),{y:0}, {
        y: 300,
        ease: "power3.inOut",
        scrollTrigger: {
          trigger: q(".explore-text2"),
          start: "top 90%",
          end: "top end",
          scrub: true,
          markers: false,
          id: "scrub"
        }
      });

      gsap.fromTo(q(".explore-text"),{y:0}, {
        y: 300,
        ease: "power3.inOut",
        scrollTrigger: {
          trigger: q(".explore-text"),
          start: "top 80%",
          end: "top end",
          scrub: true,
          markers: false,
          id: "scrub"
        }
      });

      gsap.fromTo(q(".services-heading"), {x:-100, opacity:0}, {
        x: 0,
        opacity:1,
        scrollTrigger: {
          trigger: q(".services-heading"),
          start: "top 90%",
          end: "top end",
          scrub: true,
          markers: false,
          id: "scrub"
        }
      });
      gsap.fromTo(q(".locations"), {opacity:0}, {
        x: 0,
        opacity:1,
        scrollTrigger: {
          trigger: q(".locations"),
          start: "top 90%",
          end: "bottom 50%",
          scrub: true,
          markers: false,
          id: "scrub"
        }
      });
      gsap.fromTo(q(".animated-boxes"), {opacity:0}, {
        x: 0,
        opacity:1,
        scrollTrigger: {
          trigger: q(".animated-boxes"),
          start: "top 90%",
          end: "bottom 50%",
          scrub: true,
          markers: false,
          id: "scrub"
        }
      });
      gsap.fromTo([q(".head"),q(".email"),], { opacity:0, x:-100}, {
        x: 0,
        opacity:1,
        stagger:{amount:0.3},
        scrollTrigger: {
          trigger: [q(".head"),q(".email"),],
          start: "top 80%",
          end: "bottom 50%",
          scrub: true,
          markers: false,
          id: "scrub"
        }
      });
      
    })
    return () => ctx.revert()
	}, [root]);

  return (
    <>
      <Navbar setNavOpen={setNavOpen}/>
      <div className="center-section" style={{minHeight :'80vh'}}>
        <div className="left-section">
          <Breadcrums />
          <Heading  head1={head1}/>
        </div>
        <div className="right-section">
          <div className="img-snippet" style={{zIndex:1}}>
            <img src={require("./assets/carbon1.png")} alt="snippets"></img>
          </div>
          {/* <div className="description">
          <div className="desc1">
            <span style={{ color: "#0ED200" }}>root@mxnxn:-$ </span>
            <span style={{ color: "#ffffff" }}>npm i && npm start</span>
            <br />
            <span style={{ color: "#ffffff" }}>. . .</span>
            <br />
            <span style={{ color: "#ffffff", display: "flex" }}>
              Local:{" "}
              <span style={{ marginLeft: "auto",paddingRight:"10%" }}>
                http://localhost:
                <span style={{ fontWeight: "bold" }}>3000</span>
              </span>
            </span>
            <span style={{ color: "#ffffff", display: "flex" }}>
              {" "}
              On Your Network:{" "}
              <span style={{ marginLeft: "auto",paddingRight:"10%" }}>
                http://10.0.0.69:
                <span style={{ fontWeight: "bold" }}>
                  3000
                </span>
              </span>{" "}
            </span>
            <span style={{ color: "#ffffff", display: "flex" }}>. . .</span>
            <span style={{ color: "#0ED200" }}>root@mxnxn:-$ </span>
          </div>
        </div> */}
        </div>
		
        <div className="explore-text">DESIGNER</div>
				<div className="explore-text2">DEVELOPER</div>

        {/* <Illustration /> */}
      </div>
        <section className='explore-text3' style={{cursor:'pointer'}}>
          <p class="scroll">
            Just scroll it dude!
          </p>
        </section>
        <Services/>
        <Contact/>
    </>
  );
};

export default LandingPage;
