import { dynamicURL } from "Constants/Shared";
import { PROJECTS } from "Constants/Shared";
import React from "react";

import { AiOutlineSwapRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const BottomSection = (props) => {
	return (
		<>
		<div style={{ display: "flex", flex: 1, alignItems:'center' }} className="boxes">
			<div className="locations" >
				<span>Explore :</span><br/>
				{PROJECTS.map((el) => (
					<>
					<a
						href={dynamicURL(el.url)}
						rel="noreferrels"
						key={el.name}
						// onMouseEnter={() => handleCity(el.image, cityBackground)}
						// onMouseOut={() => handleCityReturn(cityBackground)}
					>
						{el.name}
					</a> 
					</>
				))} 
			</div>
		</div>
		<div style={{ display: "flex", flex: 1}} className="boxes animated-boxes">
			
			<div className="info-box">
				<div className="info-box-title" style={{marginTop:6}}>Frontend</div>
				<p className="info-box-desc">
					HTML5, CSS, SCSS, ReactJS, Redux, NextJS, Webpack, Microfrontend, Boostrap, Tailwind, AngularJS, MozzilaPDF, PDFjs, React Drag-n-Drop, and more.
				</p>
				<hr />
				<div className="info-box-title" style={{marginTop:6}}>Databases</div>
				<p className="info-box-desc">
					I've professional experience with SQL, MongoDB, Postgre, and GraphQL. 
				</p>
				<div className="info-box-link">
					<Link to="/contact-us">
						Contact <AiOutlineSwapRight />
					</Link>
				</div>
			</div>
			<div className="suggest-box">
				<p className="small-head">Moreover</p>
				<div className="info-box-title">Backend</div>
				<br/>
				<p className="info-box-desc"> 
				NodeJS, DJango Python, Flask, Java Spring, Docker, Jenkins, Github, Azure, AWS.</p>
				<br/>
				<hr/>
				<p className="info-box-desc" style={{marginTop:24}}>
					I’m active on <a href="https://linkedin.com/in/manan-soni" style={{paddingBottom:'2px'}} className="poppins-highlight">Linkedin</a> or just Email!
				</p>
				<div className="info-box-link">
					<Link to="/contact-us" style={{marginTop:'auto'}}>
						Contact <AiOutlineSwapRight />
					</Link>
				</div>
			</div>
		</div>
		</>

	);
};

export default BottomSection;
