import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { Link  } from "react-router-dom";
import {
	staggerText,
	staggerReveal,
	fadeInUp,
	handleHover,
	handleHoverExit,
	staggerRevealClose,
} from "./Animations";

import { AiOutlineSwapRight, AiOutlineClose } from "react-icons/ai";

const Navbar = ({ setNavOpen }) => {
	
	let menuLayer = useRef(null);
	let reveal1 = useRef(null);
	let reveal2 = useRef(null);
	let line1 = useRef(null);
	let line2 = useRef(null);
	let line3 = useRef(null);
	let info = useRef(null);

	const [state, setState] = useState({
		initial: false,
		clicked: null,
		menuName: "Menu",
	});


	useEffect(() => {
		// If the menu is open and we click the menu button to close it.
		if (state.clicked === false) {
			setNavOpen(false)
			// If menu is closed and we want to open it.
			staggerRevealClose(reveal2, reveal1);
			// Set menu to display none
			gsap.to(menuLayer, { duration: 1, css: { display: "none" } });
		} else if (state.clicked === true || (state.clicked === true && state.initial === null)) {
			setNavOpen(true)
			// Set menu to display block
			gsap.to(menuLayer, { duration: 0, css: { display: "block" } });
			//Allow menu to have height of 100%
			gsap.to([reveal1, reveal2], {
				duration: 0,
				opacity: 1,
				height: "100%",
			});
			staggerReveal(reveal1, reveal2);
			fadeInUp(info);
			staggerText(line1, line2, line3);
		}
	}, [state, setNavOpen]);

	// State of our button
	const [disabled, setDisabled] = useState(false);

	// const {navigation} = useContext(UNSAFE_NavigationContext) ;

	// //Use Effect
	// useEffect(() => {
	// 	//Listening for page changes.
	// 	navigation.listen(() => {
	// 		setState({ clicked: false, menuName: "Menu" });
	// 	});
	// }, [navigation]);

	// Toggle menu
	const handleMenu = () => {
		disableMenu();
		if (state.initial === false) {
			setState({
				initial: null,
				clicked: true,
				menuName: "Close",
			});
		} else if (state.clicked === true) {
			setState({
				clicked: !state.clicked,
				menuName: "Menu",
			});
		} else if (state.clicked === false) {
			setState({
				clicked: !state.clicked,
				menuName: "Close",
			});
		}
	};

	//Determine if out menu button should be disabled
	const disableMenu = () => {
		setDisabled(!disabled);
		setTimeout(() => {
			setDisabled(false);
		}, 1500);
		return disabled;
	};

	return (
		<>
			<div ref={(el) => (menuLayer = el)} className="hamburger-menu">
				<div ref={(el) => (reveal1 = el)} className="menu-secondary-background-color"></div>
				<div ref={(el) => (reveal2 = el)} className="menu-layer">
					<div className="menu-city-background"></div>
					<div className="container jc-center">
						<div className="wrapper">
							<div className="menu-links">
								<nav>
									<ul>
										<li>
											<Link
												onMouseEnter={(e) => handleHover(e)}
												onMouseOut={(e) => handleHoverExit(e)}
												// ref={(el) => (line1 = el)}
												to="/"
												
											>
												Github
											</Link>
										</li>
										<li>
											<Link
												onMouseEnter={(e) => handleHover(e)}
												onMouseOut={(e) => handleHoverExit(e)}
												// ref={(el) => (line1 = el)}
												to="/"
												
											>
												Linkedin
											</Link>
										</li>
										<li>
											<Link
												onMouseEnter={(e) => handleHover(e)}
												onMouseOut={(e) => handleHoverExit(e)}
												// ref={(el) => (line1 = el)}
												to="/"
												
											>
												AI/ML
											</Link>
										</li>
										
										
									</ul>
								</nav>
								<div ref={(el) => (info = el)} className="info">
									<h3>Interest</h3>
									<p>
									I would love to join a company that provides an environment that fosters innovation, encourages collaboration, and offers the chance to contribute significantly to the company's AI transformation journey. It aligns perfectly with my passion for AI/ML, my desire to work with talented individuals, and my eagerness to make a meaningful impact on the organization's success.
									</p>
									
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="navbar" >
				<div className="logo">
					<Link to="/" style={{ height: "100%" }}>
						<img src={require('../assets/Vector.png')} alt="logo"></img>
					</Link>
				</div>
				<div
					className="about menu"
					onClick={() => {
						if (!disabled) {
							handleMenu();
						}
					}}
				>
					<span >{state.menuName}</span>
					{!state.clicked ? (
						<AiOutlineSwapRight style={{ verticalAlign: "middle",  }} />
					) : (
						<AiOutlineClose style={{ verticalAlign: "middle", height: 25}} />
					)}
				</div>
			</div>
		</>
	);
};

export default Navbar;
