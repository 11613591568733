import React, { useEffect } from "react";
import { gsap, Power3 } from "gsap";
import Navbar from "Navbar/Navbar";

const Contact = ({setNavOpen}) => {
	useEffect(() => {
		// gsap.fromTo([".head",".email",'.desc'],{ y: 200, opacity: 0,delay:0.5 },{ y: 0, opacity: 1, ease: Power3.easeOut, delay: 0.15, duration: 0.8, stagger:{amount:0.4} });
		gsap.fromTo(".fb-link", { x: -100, opacity: 0 }, { x: 0, opacity: 1, delay: 0.3, duration:0.6 });
	}, []);

	return (
		<>
			<div className="container d-flex center second-section" style={{marginBottom:0}}>
				<div className="center-container d-flex flex-col second-inner-section">
					<div	>
						<div className="head">Email</div>
					</div>
					<div className="email-div" >
						<div className="email"><a href="mailto:mananclassic@gmail.com">mananclassic@gmail.com</a></div>
					</div>
					{/* <div className="desc-div" style={{ overflow: "hidden" }}>
						<div className="desc">Feel free to contact me!</div>
					</div> */}
				</div>
				<div className="social-media">
					<div>
						<a className="fb-link" rel="noreferrer" href="https://linkedin.com/in/manan-soni" target="_blank">
							LinkedIn
						</a>
					</div>
					
				</div>
			</div>
		</>
	);
};

export default Contact;
